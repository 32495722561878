<template>
    <div class="events-page">
        <div class="hero">
            <HeroBasic :heading="title" accentColor="grey" :showTopographic="true" :hasHeaderOffset="true" />
        </div>

        <SubnavBlock :navigationLinks="subNav?.navigationLinks">
            <template #right-content>
                <SubscribeBlock :buttonLabel="$t('sign up for events')" mode="button" newsletterFormName="EventForm" />
            </template>
        </SubnavBlock>

        <div class="upcoming-events">
            <ContentCardBlock
                v-for="event in upcomingEvents"
                :key="event.id"
                :related="event"
                orientation="wide"
                size="100"
                :isProportionate="true"
                :hideImage="event.summary.listDisplayFormat === 'condensed'"
                :hideDescription="true"
                :pageLanguage="pageLanguage"
                :futureEvent="true"
            />
        </div>

        <div class="featured-events">
            <ContentCardBlock
                v-for="event in parsedFeaturedEvents"
                :key="event.id"
                :related="event"
                orientation="wide"
                size="full"
                :hideDescription="true"
                :pageLanguage="pageLanguage"
                :isProportionate="true"
                :hideLabel="true"
                :hidePeople="true"
                :hideDateCard="true"
            />
        </div>

        <client-only>
            <search
                id="past-events"
                :title="`${$t('past events', 1, { locale: pageLanguage })}`"
                :page-language="pageLanguage"
                :index-name="`content_${pageLanguage}`"
                :filter="`collection = events AND syndications.centers.${center}.syndicated = TRUE AND dateTime.endTimestamp < ${Date.now()}`"
                :enabled-filters="{ topics: true, regions: true, years: true, experts: true }"
                has-filters
                sort-by="dateTime.endTimestamp"
                sort-direction="desc"
            >
                <template #results="{ results }">
                    <div class="desktop-results">
                        <ContentCardBlock
                            v-for="result in results"
                            :key="result.id"
                            :related="transformContentSearchIndexToContentCardBlockProps(result)"
                            orientation="wide"
                            size="100"
                            :hideDescription="true"
                            :isProportionate="true"
                            :pageLanguage="pageLanguage"
                            class="result"
                            new-search
                        />
                    </div>
                    <div class="mobile-results">
                        <ContentCardBlock
                            v-for="result in results"
                            :key="result.id"
                            :related="transformContentSearchIndexToContentCardBlockProps(result)"
                            orientation="wide"
                            size="50"
                            :hideDescription="true"
                            :isProportionate="true"
                            :pageLanguage="pageLanguage"
                            class="result"
                            new-search
                        />
                    </div>
                </template>
            </search>
        </client-only>
    </div>
</template>

<script setup>
import transformContentSearchIndexToContentCardBlockProps from '@/utils/search/transformContentSearchIndexToContentCardBlock';
const { pageLanguage } = usePageDataStore();
const { currentCenter } = useCenters();
const center = currentCenter.value.id;
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    featuredEvents: {
        type: Array,
        default: () => [],
    },
    id: {
        type: String,
    },
    contentType: {
        type: String,
        default: '',
    },
    subNav: {
        type: Object,
        default: null,
    },
    upcomingEvents: {
        type: Array,
        default: () => [],
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});


const parsedFeaturedEvents = computed(() => {
    return props.featuredEvents?.map((event) => {
        return {
            ...event,
            summary: { ...event },
            contentType: 'events',
        };
    });
});

const headerAttributesStore = useHeaderAttributesStore();

const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Events Landing',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.events-page {
    .menu {
        @include content-section;
        @include padding-tb(vertical-space(1));
    }
}

.subnav {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    @include content-area-padding;

    .item {
        list-style: none;
    }

    .link {
        color: color(blue, dark, 0.66);
    }
}

.upcoming-events {
    @include content-section;
}

.result {
    padding-top: vertical-space(2);
    padding-bottom: vertical-space(2);
    border-bottom: 1px solid palette(bg-gray-dark);
}
.desktop-results {
    display: none;
    @include media-query(phone-mw) {
        display: block;
    }
}
.mobile-results {
    @include media-query(phone-mw) {
        display: none;
    }
}
</style>